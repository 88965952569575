import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface LineLookup extends FormElement {
  type: 'LineLookup';
  name: string;
  label: string;
  placeholder?: string;
  required: boolean;
}

const service: FormBuilderElementService<LineLookup> = {
  getEmptyElement: (id: string): LineLookup => {
    return {
      id,
      name: '',
      label: 'Site name',
      type: 'LineLookup',
      placeholder: 'Please select one…',
      required: false
    };
  }
};

export default service;
