export const buildUser = (u = {}): User =>
  Object.assign(
    {
      firstName: '',
      lastName: '',
      completedTours: [],
      jobRoleIds: [],
      employeeType: null
    },
    u
  );