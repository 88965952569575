import Vue from 'vue';
import VueRouter from 'vue-router';
import { path } from 'ramda';
import store from 'state';
import { ToastMessage } from 'state/toast/types';

Vue.use(VueRouter);

const JobRoleIndex = () => import('./admin/components/job_roles/index.vue');
const DepartmentIndex = () =>
  import('./admin/components/departments/index.vue');
const LineWorkAreaIndex = () =>
  import('./admin/components/line_work_areas/index.vue');
const LineWorkAreaMachineIndex = () =>
  import('./admin/components/line_work_area_machines/index.vue');
const MachineTypeIndex = () =>
  import('./admin/components/machine_types/index.vue');
const MachineIndex = () => import('./admin/components/machines/index.vue');
const ShiftIndex = () => import('./admin/components/shifts/index.vue');
const UserIndex = () => import('./admin/components/users/index.vue');
const ProcessAlertIndex = () =>
  import('./admin/components/process_alerts/index.vue');
const ProcedureCategories = () =>
  import('./admin/components/procedures/categories.vue');
const ProcedureIndex = () => import('./admin/components/procedures/index.vue');
const ProcedureEdit = () =>
  import('./admin/components/procedures/new-edit.vue');
const FormReportIndex = () =>
  import('./admin/components/form_reports/index.vue');
const SubmissionReport = () =>
  import('./admin/components/form_reports/submission_report.vue');
const FormIndex = () => import('./admin/components/forms/index.vue');
const FormNew = () => import('./admin/components/forms/new.vue');
const FormEdit = () => import('./admin/components/forms/edit.vue');
const HomeIndex = () => import('./components/home/index.vue');
const ProcessIndex = () => import('./components/processes/index.vue');
const ProcessEdit = () => import('./components/processes/edit.vue');
const ProcessReportSummary = () =>
  import('./components/process_report/summary.vue');
const ProcessReportInstances = () =>
  import('./components/process_report/instances.vue');
const ProcessReportEdit = () => import('./components/process_report/edit.vue');
const UserAccessActivitiesIndex = () =>
  import('./admin/components/user_access_activities/index.vue');
const PublishedDocuments = () => import('./components/documents/published.vue');
const ApprovalsReport = () => import('./components/documents/approvals.vue');
const TrashDocuments = () => import('./components/documents/trash.vue');
const DocumentVersions = () =>
  import('./components/document_versions/index.vue');
const OpenTasksIndex = () =>
import('./components/open_tasks/index.vue');
const Error404 = () => import('./components/errors/404.vue');
const Error500 = () => import('./components/errors/500.vue');

export interface Route {
  path: string;
  component?: () => any;
  name?: string;
  meta?: {
    adminAuth?: boolean;
    supervisorAuth?: boolean;
    itAdminAuth?: boolean;
  };
  redirect?: string;
}

const adminOrSupervisor = {
  adminAuth: true,
  supervisorAuth: true
};

const documentNotFoundToast: ToastMessage = {
  title: 'Document not found',
  text: 'Please check your internet connection and try again, or contact support.',
  color: 'danger'
};

const draftNotFoundToast: ToastMessage = {
  title: 'Draft not found',
  text: 'Please check your internet connection and try again, or contact support.',
  color: 'danger'
};

const invalidViewCredentialsToast: ToastMessage = {
  title: 'Access credentials invalid',
  text: 'That view is accessible to supervisors and administrators only.',
  color: 'danger'
};

const invalidCredentialsToast: ToastMessage = {
  title: 'Access credentials invalid',
  text: 'Document is accessible to supervisors and administrators only.',
  color: 'danger'
};

const invalidFolderCredentialsToast: ToastMessage = {
  title: 'Access credentials invalid',
  text: 'Folder is accessible to supervisors and administrators only.',
  color: 'danger'
};

const checkUnauthorizedEmployeeType = (to, from, next) => {
  if ((['admin', 'supervisor', 'it_admin'].includes(window.currentUser.employeeType))){
    next()
  } else {
    next({ name: 'root_published_documents_path' });
    store.dispatch('ToastStore/toast', invalidViewCredentialsToast);
  }
}

// TODO Change this type from any to the proper routes array type
const admin = {
  adminAuth: true
};

export const routes: any = [
  { path: '/', component: HomeIndex, name: 'froot_path' },
  { path: '/processes', component: ProcessIndex, name: 'processes_path', feature: 'process' },
  {
    path: '/processes/:id/edit',
    component: ProcessEdit,
    name: 'process_edit_path',
    feature: 'process'
  },
  {
    path: '/process_report',
    component: ProcessReportSummary,
    name: 'process_report_summary_path',
    feature: 'process'
  },
  {
    path: '/process_report/:procedure_id/:procedure_version/:procedure_name',
    component: ProcessReportInstances,
    name: 'process_report_instances_path',
    feature: 'process'
  },
  {
    path: '/process_report/:procedure_id/:procedure_version/:procedure_name/:id/edit',
    component: ProcessReportEdit,
    name: 'process_report_edit_path',
    feature: 'process'
  },
  {
    path: '/documents/published/:folderId',
    component: PublishedDocuments,
    name: 'published_documents_path',
    beforeEnter: (to, from ,next) => {
      if (['admin', 'supervisor', 'it_admin'].includes(window.currentUser.employeeType)) {
        next();
      } else {
        store.dispatch('FolderStore/getFolderStatus', { id: to.params.folderId })
          .then((status) => {
            if (status === 'hidden') {
              next({ name: 'root_published_documents_path' });
              store.dispatch('ToastStore/toast', invalidFolderCredentialsToast);
            } else {
              next();
            }
          })
          .catch((e) => {
            console.error('failed to load folder', e)
          });
      }
    },
    feature: 'documents'
  },
  { // Used to link to the published report with a specific document in focus (eg. when rendering the page with the sidebar already open)
    path: '/documents/published/:folderId/documents/:documentId/versions/:versionId',
    component: PublishedDocuments,
    name: 'published_documents_path_focus',
    feature: 'documents'
  },
  {
    path: '/documents/published/share/:documentId',
    component: PublishedDocuments,
    name: 'published_documents_path_redirect',
    beforeEnter: (to, from, next) => {
      store.dispatch('DocumentStore/getLatestPublishedVersion', to.params.documentId)
        .then((data) => {
          next({
            name: 'published_documents_path_focus',
            params: {
              documentId: data.documentId,
              versionId: data.versionId,
              folderId: data.folders[0].id || 0
            }
          })
        })
        .catch((err) => {
          console.error('failed to load the latest published version...', err);
          next({ name: 'root_published_documents_path' });
          const documentNotFoundToast: ToastMessage = {
            title: 'Document not found',
            text: 'Please check your internet connection and try again, or contact support.'
          }
          store.dispatch('ToastStore/toast', documentNotFoundToast);
        })
    },
    feature: 'documents'
  },
  {
    path: '/documents/approvals/:folderId',
    component: ApprovalsReport,
    name: 'approvals_report_path',
    beforeEnter: checkUnauthorizedEmployeeType,
    meta: adminOrSupervisor,
    feature: 'documents'
  },
  { // Used to link to the approvals report with a specific version in focus (eg. when rendering the page with the sidebar already open)
    path: '/documents/approvals/:folderId/documents/:documentId/versions/:versionId',
    component: ApprovalsReport,
    name: 'approvals_report_path_focus',
    beforeEnter: checkUnauthorizedEmployeeType,
    meta: adminOrSupervisor,
    feature: 'documents'
  },
  {
    path: '/documents/approvals/share/:documentId',
    component: ApprovalsReport,
    name: 'approvals_report_path_redirect',
    beforeEnter: (to, from, next) => {
      if (['admin', 'supervisor', 'it_admin'].includes(window.currentUser.employeeType)) {
        store.dispatch('VersionStore/getLatestDraftVersion', to.params)
          .then((data) => {
            next({
              name: 'approvals_report_path_focus',
              params: {
                documentId: data.documentId,
                versionId: data.versionId,
                folderId: data.folders[0].id || 0
              }
            })
          })
          .catch((err) => {
            console.error('failed to load the draft version...', err);
            next({ name: 'root_approvals_report_path' });
            store.dispatch('ToastStore/toast', documentNotFoundToast);
          });
      } else {
        next({ name: 'root_published_documents_path' });
        store.dispatch('ToastStore/toast', invalidCredentialsToast);
      }
    },
    feature: 'documents'
  },
  {
    path: '/documents/trash/:folderId',
    component: TrashDocuments,
    name: 'trash_documents_path',
    beforeEnter: checkUnauthorizedEmployeeType,
    feature: 'documents'
  },
  {
    path: '/documents/published',
    component: PublishedDocuments,
    name: 'root_published_documents_path',
    feature: 'documents'
  },
  {
    path: '/documents/approvals',
    component: ApprovalsReport,
    name: 'root_approvals_report_path',
    beforeEnter: checkUnauthorizedEmployeeType,
    feature: 'documents'
  },
  {
    path: '/documents/trash',
    component: TrashDocuments,
    name: 'root_trash_documents_path',
    beforeEnter: checkUnauthorizedEmployeeType,
    feature: 'documents'
  },
  {
    path: '/documents/:documentId',
    component: DocumentVersions,
    name: 'document_versions_path',
    beforeEnter: (to, from, next) => {
      if (['admin', 'supervisor', 'it_admin'].includes(window.currentUser.employeeType)) {
        next();
      } else {
        next({ name: 'root_published_documents_path' });
        store.dispatch('ToastStore/toast', invalidCredentialsToast);
      }
    },
    feature: 'documents'
  },
  { // Used to link to the version management report with a specific version in focus (when navigating from an e-mail review link)
    path: '/documents/:documentId/versions/:versionId',
    component: DocumentVersions,
    name: 'document_versions_path_focus',
    beforeEnter: (to, from, next) => {
      if (['admin', 'supervisor', 'it_admin'].includes(window.currentUser.employeeType)) {
        store.dispatch('VersionStore/inDocument', [to.params.documentId, null])
          .then(() => {
            if (store.state.VersionStore.ids.includes(to.params.versionId)) {
              store.dispatch('VersionStore/edit', { documentId: to.params.documentId, versionId: to.params.versionId })
                .then(() => next())
                .catch((err) => console.error('failed to load version for editing', err));
            } else {
              next({ name: 'root_approvals_report_path' });
              store.dispatch('ToastStore/toast', draftNotFoundToast);
            }
          })
          .catch((err) => {
            console.error('failed to load the draft version...', err);
            next({ name: 'root_approvals_report_path' });
            store.dispatch('ToastStore/toast', draftNotFoundToast);
          })
      } else {
        next({ name: 'root_published_documents_path' });
        store.dispatch('ToastStore/toast', invalidCredentialsToast);
      }
    },
    feature: 'documents'
  },
  {
    path: '/open_tasks',
    component: OpenTasksIndex,
    name: 'open_tasks_path',
    feature: 'process'
  },
  {
    path: '/admin/job_roles',
    component: JobRoleIndex,
    name: 'job_roles_path',
    meta: admin
  },
  {
    path: '/admin/departments',
    component: DepartmentIndex,
    name: 'departments_path',
    meta: admin
  },
  {
    path: '/admin/departments/:department_id/:department_name/line_work_areas',
    component: LineWorkAreaIndex,
    name: 'line_work_areas_path',
    meta: admin
  },
  {
    path: '/admin/departments/:department_id/:department_name/line_work_areas/:lwa_id/:lwa_name',
    component: LineWorkAreaMachineIndex,
    name: 'line_work_area_machines_path',
    meta: admin
  },
  {
    path: '/admin/machine_types',
    component: MachineTypeIndex,
    name: 'machine_types_path',
    meta: admin
  },
  {
    path: '/admin/machine_types/:machine_type_id/:machine_type_name/machines',
    component: MachineIndex,
    name: 'machines_path',
    meta: admin
  },
  {
    path: '/admin/shifts',
    component: ShiftIndex,
    name: 'shifts_path',
    meta: admin
  },
  {
    path: '/admin/users',
    component: UserIndex,
    name: 'users_path',
    meta: admin
  },
  {
    path: '/admin/processes/alerts',
    component: ProcessAlertIndex,
    name: 'process_alerts_path',
    meta: adminOrSupervisor,
    feature: 'process'
  },
  {
    path: '/admin/procedures',
    component: ProcedureCategories,
    name: 'procedure_categories_path',
    meta: admin,
    feature: 'process'
  },
  {
    path: '/admin/procedures/:process_category_id/:process_category_name',
    component: ProcedureIndex,
    name: 'procedures_path',
    meta: admin,
    feature: 'process'
  },
  {
    path:
      '/admin/procedures/:process_category_id/:process_category_name/:id/:version',
    component: ProcedureEdit,
    name: 'edit_procedure_path',
    meta: admin,
    feature: 'process'
  },
  {
    path: '/admin/form_reports',
    component: FormReportIndex,
    name: 'form_reports_path',
    meta: adminOrSupervisor,
    feature: 'process'
  },
  {
    path:
      '/admin/form_reports/:form_id/versions/:form_version/submission_report',
    component: SubmissionReport,
    name: 'submission_report_path',
    meta: adminOrSupervisor,
    feature: 'process'
  },
  {
    path: '/admin/forms',
    component: FormIndex,
    name: 'forms_path',
    meta: admin,
    feature: 'process'
  },
  {
    path: '/admin/forms/new',
    component: FormNew,
    name: 'new_form_path',
    meta: admin,
    feature: 'process'
  },
  {
    path: '/admin/forms/:id/:version/edit',
    component: FormEdit,
    name: 'edit_form_path',
    meta: admin,
    feature: 'process'
  },
  {
    path: '/admin/users/activities',
    component: UserAccessActivitiesIndex,
    name: 'user_access_activities_path',
    meta: { itAdminAuth: true },
    feature: 'process'
  },

  { path: '/500', component: Error500, name: 'error_path' },
  { path: '/404',
    component: Error404,
    name: 'not_found_path',
    beforeEnter: (to, from, next) => {
      // If we got here via post-login redirect there may be an unreachable page saved in cookies.
      if (to.redirectedFrom && to.redirectedFrom.includes('post-login')) {
        const destination = to.redirectedFrom.replace('?post-login', '');
        // Check if the destination is actually a valid route.
        if (routes.some(route => route.path === destination)) {
          next();
        } else {
          next('/');
        }
      }
    }
  },
  { path: '*', redirect: '/404' }
]
  .filter((route) => {
    if (!window.App.features.includes('documents') && (route.feature as AppFeature) === 'documents') {
      return false;
    }
    if (!window.App.features.includes('process') && (route.feature as AppFeature) === 'process') {
      return false;
    }
    return true;
  });

const router = new VueRouter({
  mode: 'history',
  // base: `${I18n.prefix}`,
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active'
});

const checkRole = (to, role) =>
  (path(['meta', 'adminAuth'], to) && role === 'admin') ||
  (path(['meta', 'supervisorAuth'], to) && role === 'supervisor') ||
  (path(['meta', 'itAdminAuth'], to) && role === 'it_admin');

export const beforeEach = (to, from, next) => {
  const role = path(['employeeType'], window.currentUser);
  if (!window.currentUser && to.path !== from.path ) {
    // The sign in is handled outside this app, so we need to redirect rather
    // than just render a particular view
    window.location.href = '/users/sign_in?redirect_to=' + to.fullPath;
  } else if (checkRole(to, role)) {
    next();
  } else if (
    !path(['meta', 'adminAuth'], to) &&
    !path(['meta', 'supervisorAuth'], to) &&
    !path(['meta', 'itAdminAuth'], to)
  ) {
    next();
  } else {
    next('/');
  }
};

router.beforeEach(beforeEach);

export default router;
