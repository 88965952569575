import helpers from 'admin/helpers';
import { clone, equals } from 'ramda';

const FormStore = {
  namespaced: true,
  state: {
    forms: [],
    form: {},
    disableRequests: 0,
    formIsSubmittable: true,
    fields: [],
    errors: {},
    progress: '',
    pagination: {},
    model: {},
    savedForm: {},
    latestVersion: {},
    latestVersionFields: [],
    formsByIdAndVersion: {}
  },
  mutations: {
    disableFormSubmit(state) {
      state.disableRequests = state.disableRequests + 1;
      state.formIsSubmittable = false;
    },
    enableFormSubmit(state) {
      state.disableRequests = state.disableRequests - 1;
      if (state.disableRequests === 0) {
        state.formIsSubmittable = true;
      }
    },
    resetFormSubmit(state) {
      state.disableRequests = 0;
      state.formIsSubmittable = true;
    },
    one(state, data) {
      state.errors = {};
      state.form = data.form;
      state.fields = data.fields;
      state.savedForm = clone(data.form);
      return state;
    },
    latestVersion(state, data) {
      state.latestVersion = data.form;
      state.latestVersionFields = data.fields;
    },
    many(state, data) {
      state.forms = data.forms;
      state.pagination = data.pagination;
      return state;
    },
    progress(state, step) {
      state.progress = step;
      return state.progress;
    },
    errors(state, data) {
      state.errors = helpers.showErrors(data);
      return state.errors;
    },
    updateModel(state, { key, value }) {
      state.model = { ...state.model, [key]: value };
    },
    clearModel(state) {
      state.model = {};
    },
    setModel(state, model) {
      state.model = model;
    },
    setFormByIdAndVersion(state, { form }: { form: { formElements: any[]; form_id: Uuid; name: string; version: number; }; }) {
      state.formsByIdAndVersion[`${form.form_id}-${form.version}`] = form;
    }
  },
  getters: {
    formIsModified: (state) => !equals(state.form, state.savedForm),
    summaryFormIsModified: (state) =>
      !equals(state.form.form_id, state.savedForm.form_id) ||
      !equals(state.form.version, state.savedForm.version),
    formByIdAndVersion: (state) => ({ formId, formVersion }: { formId: Uuid; formVersion: number; }) =>
      state.formsByIdAndVersion[`${formId}-${formVersion}`]
  },
  actions: {
    index(context, query) {
      const opts = {};
      Object.keys(query).forEach((prop) => {
        opts[`q[${prop}_cont]`] = query[prop];
      });
      $.ajax({
        url: 'admin/forms',
        type: 'get',
        data: opts,
        success: (data) => {
          context.commit('many', data);
        }
      });
    },
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/forms',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    report(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/forms/report',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    autocomplete(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/forms/autocomplete',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data.forms);
          },
          error: reject
        });
      });
    },
    get(context, { id, version }) {
      context.commit('progress', 'loading');

      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/forms/${id}/${version}`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            context.commit('progress', 'success');

            resolve(data.form);
          },
          error: reject
        });
      });
    },
    getFormByIdAndVersion(context, { formId, formVersion }) {
      return new Promise((resolve, reject) => {
        const form = context.state.formsByIdAndVersion[`${formId}-${formVersion}`];
        if (form) {
          return resolve(form);
        }

        $.ajax({
          url: `admin/forms/${formId}/${formVersion}`,
          type: 'get',
          success: (data) => {
            context.commit('setFormByIdAndVersion', data);
            resolve(data.form);
          },
          error: reject
        });
      });
    },
    latestVersion(context, { id }) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/forms/${id}`,
          type: 'get',
          success: (data) => {
            context.commit('latestVersion', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    destroy(_context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/forms/${id}`,
          type: 'delete',
          success: (data) => {
            resolve(data);
          },
          error: (data) => {
            reject(data);
          }
        });
      });
    },
    uploadFile(_context, file) {
      return helpers.uploadFile(file);
    },
    duplicate(context, { formId, formVersion }) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `/admin/forms/${formId}/${formVersion}/duplicate`,
          type: 'put',
          success: resolve,
          error: reject
        });
      });
    }
  }
};

export default FormStore;
